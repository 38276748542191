import React, { useState, useRef, useEffect } from 'react';
import Header from './components/header';
import Section from './components/section';
import Section2 from './components/section2';
import AboutSection from './components/aboutSection';
import Footer from './components/Footer';
import "./secondPage.css";
import music from "./sounds/1.mp3"
import playButton from "./images/play.png";
import pauseButton from "./images/pause.png";


const SecondPage = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const audio = new Audio(music); // Crea una instancia de Audio


  useEffect(() => {
    audio.loop = true; // Establece la reproducción en bucle

    // Reproduce el audio cuando el componente se monta
    audio.play();

    return () => {
      // Limpia la instancia de audio al desmontar el componente
      audio.pause();
      audio.removeAttribute('src');
      audio.load();
    };
  }, []);

  const handlePlay = () => {
    audio.play();
    setIsPlaying(true);
  };

  const handlePause = () => {
    audio.pause();
    setIsPlaying(false);
  };

  return (
    <div>
      <Header />
      <Section />
      <div className="barra">
        <div className="texto-animado">NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
        </div>
      </div>
      <Section2 />
      <div className="barra">
        <div className="texto-animado">NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
        </div>
      </div>
      <AboutSection />
      <div className="barra">
        <div className="texto-animado">NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
          NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA NIGGA
        </div>
      </div>
      <Footer />
      <div style={{ position: 'relative' }}>
        <button onClick={handlePlay} style={{ position: 'absolute', bottom: '20px', right: '80px', border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
          <img src={playButton} alt="Play" style={{ width: '100px', height: '100px' }} />
        </button>
        <button onClick={handlePause} style={{ position: 'absolute', bottom: '20px', right: '20px', border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
          <img src={pauseButton} alt="Pause" style={{ width: '100px', height: '100px' }} />
        </button>
      </div>
    </div>
  );
}

export default SecondPage;
