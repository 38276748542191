import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Asegúrate de importar Routes también
import HomePage from './HomePage';
import SecondPage from './SecondPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/home" element={<SecondPage />} />
      </Routes>
    </Router>
  );
}

export default App;
