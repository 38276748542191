import React from 'react';
import './aboutSection.css';
import banner from "../images/stole2.png"

const Footer = () => {
  return (
    <footer className="footer">
      <img src={banner} alt="Descripción de la imagen" />
    </footer>
  );
};

export default Footer;
