import React from 'react';
import textoImagen from '../images/niggabike.png';

const Section = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
      <img src={textoImagen} alt="Texto" style={{ width: '50%', marginRight: '20px', marginLeft: '200px', }} />
      <h2>A person of African descent has acquired my 2 wheeled manual method of transportation illegally.</h2>
    </div>
  );
}

export default Section;
