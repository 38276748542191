import React, { useState } from 'react';
import fondo from './images/garage.jpg';
import bike from './images/bike.png';
import audioFile from './sounds/stole.mp3';

const HomePage = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(true);
        const audio = new Audio(audioFile);
        audio.play();
        setTimeout(() => {
            window.location.href = "/home"; // Redireccionar a la siguiente página
        }, 5000); // Cambia el tiempo según la duración del audio
    };

    const overlayStyle = {
        position: 'absolute',
        content: '',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Color de fondo oscuro con opacidad
        zIndex: 1,
        transition: 'opacity 3s ease-in-out', // Transición más lenta de 2 segundos
        opacity: isClicked ? 1 : 0, // Opacidad 1 cuando se hace clic para oscurecer, 0 cuando no
    };

    const bikeStyle = {
        width: '400px',
        cursor: 'pointer',
        position: 'absolute',
        bottom: '20px',
        top: '500px',
        right: '1000px',
        filter: isHovered ? 'brightness(150%)' : 'brightness(100%)', // Restaurar el brillo del hover
        opacity: isClicked ? 0 : 1, // Opacidad 0 cuando se hace clic para desaparecer la bicicleta
        transition: 'opacity 3s ease-in-out', // Transición de opacidad de 1 segundo
    };

    const textStyle = {
        position: 'absolute',
        right: '1000px', // Ajustar la posición horizontal del texto
        top: '500px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '25px 50px',
        borderRadius: '5px',
        opacity: isHovered ? 1 : 0, // Opacidad 1 cuando se hace hover para mostrar el texto
        zIndex: 10,
        transition: 'opacity 0.3s ease-in-out', // Transición de opacidad de 0.3 segundos
    };

    return (
        <div style={{ position: 'relative', height: '100vh', backgroundImage: `url(${fondo})`, backgroundSize: 'cover', }}>
            <div onClick={handleClick} style={{ position: 'relative', cursor: 'pointer', zIndex: 2 }}>
                <img
                    src={bike}
                    alt="Imagen"
                    style={bikeStyle}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                />
                {isHovered && (
                    <div style={textStyle}>
                        <p>PICK YOUR BIKE</p>
                    </div>
                )}
            </div>
            <div style={overlayStyle}></div>
        </div>
    );
}

export default HomePage;
